
import platformClient from 'purecloud-platform-client-v2'
import genesysCloud from './services/genesyscloud-service'
import { defineComponent } from 'vue'
import Navbar from './components/Navbar.vue'
import config from './config/config'
import { useI18n } from 'vue-i18n'
import { initializeClientApp } from './services/client-app-service'

export const _environments = [
  {
    name: 'fedramp-use2-core',
    env: 'fedramp',
    region: 'us-east-2',
    status: 'stable',
    publicDomainName: 'use2.us-gov-pure.cloud',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod',
    env: 'prod',
    region: 'us-east-1',
    status: 'stable',
    publicDomainName: 'mypurecloud.com',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-apne1',
    env: 'prod',
    region: 'ap-northeast-1',
    status: 'stable',
    publicDomainName: 'mypurecloud.jp',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-apne2',
    env: 'prod',
    region: 'ap-northeast-2',
    status: 'stable',
    publicDomainName: 'apne2.pure.cloud',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-aps1',
    env: 'prod',
    region: 'ap-south-1',
    status: 'stable',
    publicDomainName: 'aps1.pure.cloud',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-apse2',
    env: 'prod',
    region: 'ap-southeast-2',
    status: 'stable',
    publicDomainName: 'mypurecloud.com.au',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-cac1',
    env: 'prod',
    region: 'ca-central-1',
    status: 'stable',
    publicDomainName: 'cac1.pure.cloud',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-euc1',
    env: 'prod',
    region: 'eu-central-1',
    status: 'stable',
    publicDomainName: 'mypurecloud.de',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-euw1',
    env: 'prod',
    region: 'eu-west-1',
    status: 'stable',
    publicDomainName: 'mypurecloud.ie',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-euw2',
    env: 'prod',
    region: 'eu-west-2',
    status: 'stable',
    publicDomainName: 'euw2.pure.cloud',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-sae1',
    env: 'prod',
    region: 'sa-east-1',
    status: 'stable',
    publicDomainName: 'sae1.pure.cloud',
    publicDomainAliases: [
    ]
  },
  {
    name: 'prod-usw2',
    env: 'prod',
    region: 'us-west-2',
    status: 'stable',
    publicDomainName: 'usw2.pure.cloud',
    publicDomainAliases: [
    ]
  }
]

export default defineComponent({
  name: 'Finder',
  components: {
    Navbar
  },
  data () {
    return {
      isSupervisor: false,
      agentMember: {} as platformClient.Models.User,
      userId: '',
      divisionId: '',
      userName: '',
      profile: '',
      lang: '',
      permissions: [] as string[],
      permissionViewApp: false,
      langTag: '',
      pcEnvironment: '',
      clientId: ''
    }
  },
  setup () {
    const { t } = useI18n() // Access the `t` function from `useI18n`

    return {
      t
    }
  },
  methods: {
    getEnvFromTargetEnv (targetEnv: string): string {
      const found = _environments.filter(p => p.name === targetEnv)[0]
      if (found) {
        return found.publicDomainName
      }
      return 'mypurecloud.com'
    },
    async getRoles (userId: string) {
      try {
        const res = await genesysCloud.getUserRoles(userId)
        if (res && res.permissions) {
          this.permissions = res.permissions
          this.permissionViewApp = this.permissions.some((permission) => permission.includes('integration:examplePremiumApp:view'))
          // Check for the required permission
          // if (this.permissionViewApp) {
          //   console.log('im here')
          //   // Redirect to the error page
          //   this.$router.push({ name: 'ErrorPage', query: { errorMsg: this.t('errorPermission') } })
          //   return
          // }
          // Handle supervisor role check
          this.isSupervisor = this.permissions.some((permission) => permission.includes('ui:supervisor'))
          this.profile = this.isSupervisor ? 'supervisor' : 'agent'
        }
      } catch (error) {
        console.log(error)
      }
    },
    async login (pcEnvironment: string, clientId: string) {
      try {
        await genesysCloud.loginImplicitGrant(pcEnvironment, clientId)
        history.pushState({}, '', config.redirectUri)
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUserData () {
      try {
        const data = await genesysCloud.getUsersMe()
        this.agentMember = data
        this.userId = this.agentMember.id !== undefined ? this.agentMember.id : ''
        this.divisionId = this.agentMember.division && this.agentMember.division.id !== undefined ? this.agentMember.division.id : ''
        this.userName = this.agentMember.name !== undefined ? this.agentMember.name : ''
        await this.getRoles(this.userId)
      } catch (error) {
        console.log(error)
      }
    },
    setLanguage (lang: string) {
      const { locale, mergeLocaleMessage } = useI18n()
      // Dynamically import the language file
      import(`./locales/${lang}.json`)
        .then(messages => {
          // Merge the language messages for the selected locale
          mergeLocaleMessage(lang, messages.default)

          // Set the new locale
          locale.value = lang
        })
        .catch(error => {
          console.error(`Failed to load language file for ${lang}:`, error)
        })
    },
    handleError (errorMessage: string) {
      this.$router.push({
        name: 'ErrorPage',
        query: { errorMsg: errorMessage } // Pass error message via props
      })
    }
  },
  async created (): Promise<void> {
    console.log('App created')
    console.log(`Redirect URI: ${config.redirectUri}`)
    try {
      const urlParams = new URLSearchParams(window.location.search)
      let clientId = urlParams.get('clientId')
      const langTag = urlParams.get('langTag')
      const targetEnv = urlParams.get('targetEnv')

      let pcEnvironment: string | null = null

      // Set pcEnvironment based on targetEnv or from local storage
      if (targetEnv) {
        pcEnvironment = this.getEnvFromTargetEnv(targetEnv)
        if (pcEnvironment) {
          localStorage.setItem('pcEnvironment', pcEnvironment)
        } else {
          throw new Error('Invalid targetEnv')
        }
      } else {
        pcEnvironment = localStorage.getItem('pcEnvironment')
        if (!pcEnvironment) {
          throw new Error('pcEnvironment is missing in both URL and local storage')
        }
      }

      // Handle clientId
      if (clientId) {
        localStorage.setItem('clientId', clientId)
      } else {
        clientId = localStorage.getItem('clientId')

        if (!clientId) {
          throw new Error('clientId is missing! Please provide a valid clientId in the URL.')
        }
      }

      let _langTag
      // Handle langTag
      if (langTag) {
        localStorage.setItem('langTag', langTag)
        _langTag = langTag
      } else {
        _langTag = localStorage.getItem('langTag')
        if (!_langTag) {
          _langTag = 'en-us'
        }
      }

      config.pcEnvironment = pcEnvironment
      config.clientId = clientId
      config.langTag = _langTag

      console.log('pcEnvironment:', pcEnvironment)
      console.log('Using clientId:', clientId)
      console.log('Using langTag:', _langTag)
      initializeClientApp(pcEnvironment)
      // Set language
      this.setLanguage(_langTag)

      // Proceed with login after extracting parameters
      await this.login(pcEnvironment, clientId as string)
      this.$router.push('/')
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.handleError(error.message)
      } else {
        this.handleError('An unknown error occurred.')
      }
    }
  },
  async mounted (): Promise<void> {
    console.log('App mounted')
    await this.fetchUserData()
  }
})
